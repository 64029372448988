/* 自定义 */
.flex-center {
  @apply flex justify-center items-center;
}

.bento-border {
  @apply border-2 rounded-xl
}

.be-center-vertically {
  @apply top-1/2 -translate-y-1/2
}

.be-center-horizontally {
  @apply left-1/2 -translate-x-1/2
}

.be-center {
  @apply top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2
}

.spin {
  pointer-events: none;
}

/* spin mask */
.spin::before {
  content: " ";
  display: block;
  z-index: 99;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
}

/* spin loading */
.spin::after {
  content: " ";
  z-index: 100;
  width: 24px;
  height: 24px;
  top: 50%;
  left: 50%;
  position: absolute;
  border: 2px solid white;
  border-top-color: transparent;
  border-left-color: transparent;
  border-radius: 100%;
  animation: spin infinite 1s linear;
}

@keyframes beWild {
  from {
    left: -50%;
  }

  to {
    left: 0;
  }
}

@keyframes openLine {
  from {
    width: 150px;
  }

  to {
    width: 100%;
  }
}

/* 添加一个类，应用 beWild，仅执行一次，持续 300ms */
.be-wild {
  animation: beWild 300ms forwards;
}

.line {
  animation: openLine 300ms forwards;
}

/* 
.stack-close {
  @apply relative;
  & > * {
    @apply absolute top-0 left-0 transition-all;
  }
  & > * {
    transform: translateY(20%) scale(0.80);
    z-index: 1;
    @apply opacity-60;
  }
  & > *:nth-child(4)  {
    transform: translateY(15%) scale(0.85);
    z-index: 2;
    @apply opacity-70;
  }
  & > *:nth-child(3) {
    transform: translateY(10%) scale(0.9);
    z-index: 3;
    @apply opacity-80;
  }
  & > *:nth-child(2) {
    transform: translateY(5%) scale(0.95);
    z-index: 4;
    @apply opacity-90;
  }
  & > *:nth-child(1) {
    transform: translateY(0) scale(1);
    z-index: 5;
    @apply opacity-100;
  }
}

.stack-open {
  @apply relative;
  & > * {
    @apply absolute top-auto left-auto transition-all opacity-100;
  }
  & > * {
    transform: translateY(400%) scale(1);
    z-index: 1;
  }
  & > *:nth-child(4) {
    transform: translateY(300%) scale(1);
    z-index: 2;
  }
  & > *:nth-child(3) {
    transform: translateY(200%) scale(1);
    z-index: 3;
  }
  & > *:nth-child(2) {
    transform: translateY(100%) scale(1);
    z-index: 4;
  }
  & > *:nth-child(1) {
    transform: translateY(0) scale(1);
    z-index: 5;
  }
} */

.ring-graph {
  position: relative;
  /* --w: 50vmin; */
  /* width: var(--w);
  height: var(--w); */
  display: flex;
  /* --show: 5; */
  /* put to the number you want to have */
  /* --angle: 36; */
  /* put to the angle between them */
  /* --num: calc(360 / var(--angle)); */
  /* transform: rotate(calc(((var(--show) + 1) / 2 * var(--angle)) * -1deg)); */
}

.element {
  position: absolute;
  /* width: calc(100% / 6); */
  height: 50%;
  top: 0;
  transform-origin: center bottom;
  /* left: calc(50% - 1vw); */
  /* transform: rotate(calc(360deg * var(--n) / var(--num))); */
}

.element>* {
  /* background-position: center center;
  background-size: contain;
  background-repeat: no-repeat; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  aspect-ratio: 1 / 1.5;
}